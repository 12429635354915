import store from "../store";
import { getProjectConst } from '@/utils/auth';
var wsUrl = getProjectConst('WebSocketServerUrl');
var ws = '';
var lockReconnect = false;
var heartMsg = 'PONG'; //心跳文字

var heartCheck = {
  timeout: 3000,
  //心跳检测时长
  timeoutObj: null,
  //定时变量
  reset: function reset() {
    // 重置定时
    clearTimeout(this.timeoutObj);
    return this;
  },
  start: function start() {
    //开启定时
    this.timeoutObj = setTimeout(function () {
      //心跳时间内收不到消息，主动触发连接关闭，开始重连
      ws.close();
    }, this.timeout);
  }
}; // 建立连接

export function createSocket() {
  try {
    if ('WebSocket' in window) {
      ws = new WebSocket(wsUrl);
    } else if ('MozWebSocket' in window) {
      ws = new MozWebSocket(wsUrl);
    } else {
      console.log('当前浏览器不支持websocket协议,建议使用现代浏览器');
      return;
    }

    ws.onopen = onopenWS;
    ws.onmessage = onmessageWS;
    ws.onerror = onerrorWS;
    ws.onclose = oncloseWS;
  } catch (e) {
    reconnect();
  }
} // 重连

function reconnect() {
  if (lockReconnect) {
    return;
  }

  lockReconnect = true; //没连接上会一直重连，设置延迟避免请求过多

  setTimeout(function () {
    createSocket();
    lockReconnect = false;
  }, 2000);
} // 打开连接后发送登录命令


export function onopenWS() {
  wsLogin();
  lockReconnect = false; // 心跳检测重置

  heartCheck.reset().start();
} // 连接失败重连

export function onerrorWS() {
  ws.close();
  reconnect();
}
export function oncloseWS() {
  reconnect();
} // 数据接收统一处理

export function onmessageWS(e) {
  heartCheck.reset().start(); // 忽略心跳消息

  if (e.data !== heartMsg) {
    var data = JSON.parse(e.data);

    if (data.Type === 'Command') {
      // 创建一个自定义事件-命令
      window.dispatchEvent(new CustomEvent('websocketCommand', {
        detail: {
          data: data
        }
      }));
    } else if (data.Type === 'Message') {
      // 创建一个自定义事件-消息
      window.dispatchEvent(new CustomEvent('websocketMessage', {
        detail: {
          data: data
        }
      }));
    }
  }
}
/**
 * 登录命令
 */

export function wsLogin(token) {
  var data = {
    ClientName: 'PC',
    Token: token ? token : store.state.user.token
  };
  wsSendCommand('Login', data);
}
/**
 * 发送命令
 */

export function wsSendCommand(commandName, commandData) {
  var msg = {
    Type: 'Command',
    Name: commandName,
    Data: commandData
  };

  if (ws.readyState === 1) {
    ws.send(JSON.stringify(msg));
  } else {
    setTimeout(function () {
      ws.send(JSON.stringify(msg));
    }, 3000);
  }
}
/**
 * 发送消息
 */

export function wsSendMsg(msgName, msgData) {
  var msg = {
    Type: 'Message',
    Name: msgName,
    Data: msgData
  };

  if (ws.readyState === 1) {
    ws.send(JSON.stringify(msg));
  } else {
    setTimeout(function () {
      ws.send(JSON.stringify(msg));
    }, 3000);
  }
}
/**
 * 订阅新订单提醒
 */

export function subscribeNewMemberOrder() {
  localStorage.setItem('SubscribeNewMemberOrderStatus', '1');
  wsSendCommand('Subscribe', 'ChannelNewMemberOrder');
}
/**
 * 取消订阅新订单提醒
 */

export function unSubscribeNewMemberOrder() {
  localStorage.setItem('SubscribeNewMemberOrderStatus', '0');
  wsSendCommand('UnSubscribe', 'ChannelNewMemberOrder');
}