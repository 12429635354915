import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.function.name";
import _objectSpread from "/Users/wuzaiqi/dev/vue/water_vue_vip/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import { asyncRoutes, constantRoutes } from '@/router';
import Layout from '@/layout/index';
import { leadRoutes } from '@/router/lead.js';
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */

function hasPermission(route, roles) {
  if (route.meta && route.meta.roles) {
    return roles.some(function (role) {
      return route.meta.roles.includes(role);
    });
  } else {
    return true;
  }
}
/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */


export function filterAsyncRoutes(routes, roles) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route); // TODO 暂时去掉权限验证
    // if (hasPermission(tmp, roles)) {


    if (tmp.children) {
      tmp.children = filterAsyncRoutes(tmp.children, roles);
    }

    res.push(tmp); // } else {
    // }
  });
  return res;
}
var state = {
  routes: [],
  addRoutes: []
};
var mutations = {
  SET_ROUTES: function SET_ROUTES(state, routes) {
    state.addRoutes = routes;
    state.routes = constantRoutes.concat(routes);
  }
};
var actions = {
  generateRoutes: function generateRoutes(_ref, roles) {
    var commit = _ref.commit;
    return new Promise(function (resolve) {
      var accessedRoutes;

      if (roles.includes('All')) {
        accessedRoutes = asyncRoutes || [];
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);

        if (accessedRoutes.length > 0) {
          for (var index = accessedRoutes.length - 1; index >= 0; index--) {
            var item = accessedRoutes[index];

            if (item.children) {
              if (item.children.length === 0) {
                accessedRoutes.splice(index, 1);
              }
            }
          }
        }
      } // 重组路由配置数组-------------------------


      var accessedRoutesTemp = [];

      if (accessedRoutes.length > 0) {
        for (var i = 0; i < accessedRoutes.length; i++) {
          var _item = {
            path: accessedRoutes[i].path,
            component: accessedRoutes[i].component,
            redirect: accessedRoutes[i].redirect,
            name: accessedRoutes[i].name,
            meta: accessedRoutes[i].meta,
            children: []
          };
          var activeMenuRoot = accessedRoutes[i].path;

          if (accessedRoutes[i].children.length > 0) {
            for (var j = 0; j < accessedRoutes[i].children.length; j++) {
              var itemChildren = {};

              if (accessedRoutes[i].children[j].hasOwnProperty('myLead') === true) {
                if (leadRoutes.hasOwnProperty(accessedRoutes[i].children[j].myLead) === true) {
                  if (leadRoutes[accessedRoutes[i].children[j].myLead].length > 0) {
                    // 当前页面，在左侧路由所指向的path
                    var activeMenu = '';

                    for (var k = 0; k < leadRoutes[accessedRoutes[i].children[j].myLead].length; k++) {
                      //是否隐藏
                      var isHidden = true; //权限范围内
                      // TODO 暂时去掉权限验证
                      // if (this._vm.roles.filter(v => leadRoutes[accessedRoutes[i].children[j].myLead][k].meta.roles.includes(v)).length > 0) {

                      if (activeMenu === '') {
                        isHidden = false;
                        activeMenu = activeMenuRoot + '/' + leadRoutes[accessedRoutes[i].children[j].myLead][k].path; //如果横向菜单有特定标题，则新增一个重定向路由项，其它的路由

                        if (accessedRoutes[i].children[j].hasOwnProperty('myLeadTitle') === true) {
                          activeMenu = activeMenu + '_my_lead_path';
                          var topMeta = {
                            title: accessedRoutes[i].children[j].myLeadTitle.toString(),
                            roles: leadRoutes[accessedRoutes[i].children[j].myLead][k].meta.roles,
                            activeMenu: activeMenu.replace('//', '/')
                          };
                          var itemChildrenTop = {
                            path: activeMenu,
                            redirect: leadRoutes[accessedRoutes[i].children[j].myLead][k].path,
                            name: '',
                            meta: topMeta,
                            hidden: isHidden
                          };

                          _item.children.push(itemChildrenTop);

                          isHidden = true;
                        }
                      }

                      leadRoutes[accessedRoutes[i].children[j].myLead][k].meta.activeMenu = activeMenu.replace('//', '/');
                      itemChildren = {
                        path: leadRoutes[accessedRoutes[i].children[j].myLead][k].path,
                        component: leadRoutes[accessedRoutes[i].children[j].myLead][k].component,
                        name: leadRoutes[accessedRoutes[i].children[j].myLead][k].name,
                        meta: leadRoutes[accessedRoutes[i].children[j].myLead][k].meta,
                        hidden: isHidden
                      };

                      _item.children.push(itemChildren); // }

                    }
                  }
                }
              } else {
                itemChildren = {
                  path: accessedRoutes[i].children[j].path,
                  component: accessedRoutes[i].children[j].component,
                  name: accessedRoutes[i].children[j].name,
                  meta: accessedRoutes[i].children[j].meta,
                  hidden: accessedRoutes[i].children[j].hidden
                };

                _item.children.push(itemChildren);
              }
            }
          }

          accessedRoutesTemp.push(_item);
        }
      }

      accessedRoutes = accessedRoutesTemp;

      if (accessedRoutes.length > 0) {
        for (var _index = accessedRoutes.length - 1; _index >= 0; _index--) {
          var _item2 = accessedRoutes[_index];

          if (_item2.children) {
            if (_item2.children.length === 0) {
              accessedRoutes.splice(_index, 1);
            }
          }
        }
      } // 重组路由配置数组(完成)-------------------------


      commit('SET_ROUTES', accessedRoutes);
      resolve(accessedRoutes);
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};