import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _objectSpread from "/Users/wuzaiqi/dev/vue/water_vue_vip/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
// eslint-disable-next-line no-unused-vars
import { login as _login, logout, getInfo as _getInfo } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
import { oncloseWS, wsLogin } from '@/utils/websocket';
var state = {
  token: getToken(),
  name: '',
  avatar: '',
  roles: [],
  infos: {},
  modRight: {}
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_INFOS: function SET_INFOS(state, infos) {
    state.infos = infos;
  },
  SET_MODRIGHT: function SET_MODRIGHT(state, modRight) {
    state.modRight = _objectSpread({}, modRight);
  }
};
var actions = {
  // user login
  login: function login(_ref, userInfo) {
    var commit = _ref.commit;
    var username = userInfo.username,
        password = userInfo.password;
    return new Promise(function (resolve, reject) {
      _login({
        username: username.trim(),
        password: password
      }).then(function (response) {
        var data = response.Data;
        commit('SET_TOKEN', data.Token);
        setToken(data.Token); // 连接websocket

        wsLogin(data.Token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref2) {
    var commit = _ref2.commit,
        state = _ref2.state;
    return new Promise(function (resolve, reject) {
      _getInfo().then(function (response) {
        var data = response.Data;

        if (!data) {
          reject('验证失败，请重新登录');
        } // eslint-disable-next-line prefer-const


        var Name = data.Name,
            ModRight = data.ModRight,
            Photo = data.Photo;
        var roles = [];

        if (ModRight) {
          ModRight = JSON.parse(ModRight);
          roles = Object.keys(ModRight); //根据各个模块的具体权限，生成路由具体操作权限，如：User模块中的添加，则在roles数组中增加'User1'元素

          if (Object.keys(ModRight).length > 0) {
            for (var i = 0; i < Object.keys(ModRight).length; i++) {
              if (ModRight[Object.keys(ModRight)[i]].length > 0) {
                for (var j = 0; j < ModRight[Object.keys(ModRight)[i]].length; j++) {
                  roles.push(Object.keys(ModRight)[i] + ModRight[Object.keys(ModRight)[i]][j] + '');
                }
              }
            }
          }
        } else {
          roles = ['null'];
        }

        commit('SET_MODRIGHT', ModRight);

        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!');
        }

        commit('SET_ROLES', roles);
        commit('SET_NAME', Name);
        commit('SET_INFOS', data);
        commit('SET_AVATAR', Photo);
        resolve({
          roles: roles
        });
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref3) {
    var commit = _ref3.commit,
        state = _ref3.state;
    return new Promise(function (resolve, reject) {
      // logout(state.token).then(() => {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      resetRouter(); // 关闭websocket

      oncloseWS();
      resolve(); // }).catch(error => {
      // reject(error)
      // })
    });
  },
  // remove token
  resetToken: function resetToken(_ref4) {
    var commit = _ref4.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};