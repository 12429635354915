//自定义路由配置
export var leadRoutes = {
  // 我的水票
  ticket: [{
    path: 'ticket',
    name: 'Ticket',
    component: function component() {
      return import('@/views/ticket/index');
    },
    meta: {
      title: '我的水票',
      roles: ['Ticket']
    }
  }, {
    path: 'ticket_gift',
    name: 'TicketGift',
    component: function component() {
      return import('@/views/ticket_gift/index');
    },
    meta: {
      title: '水票转赠',
      roles: ['TicketGift']
    }
  }, {
    path: 'ticket_gift_detail',
    name: 'TicketGiftDetail',
    component: function component() {
      return import('@/views/ticket_gift_detail/index');
    },
    meta: {
      title: '转赠水票查询',
      roles: ['TicketGift']
    }
  }]
};