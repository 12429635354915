import "core-js/modules/es6.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
import '@/styles/animate.scss';
import { createSocket, subscribeNewMemberOrder, wsSendCommand } from "./utils/websocket";
import { projectConst, projectStatus, projectDict } from '@/api/public';
import { setProjectConst, setProjectStatus, setProjectDict } from '@/utils/auth';
export default {
  name: 'App',
  components: {},
  data: function data() {
    return {
      timer: '',
      unreadWeChatMessageCount: 0,
      customerServiceIconShow: false,
      isCustomerService: false,
      animationName: 'hidden'
    };
  },
  computed: {
    currentRouteName: function currentRouteName() {
      return this.$route.name;
    },
    currentUserInfo: function currentUserInfo() {
      return this.$store.state.user.infos;
    }
  },
  watch: {
    currentRouteName: function currentRouteName(val) {
      if (val === 'WeChatOfficialAccountsMessageReply' || val === undefined) {
        this.unreadWeChatMessageCount = 0;
        this.customerServiceIconShow = false;
      } else {
        if (this.isCustomerService || this.unreadWeChatMessageCount > 0) {
          this.customerServiceIconShow = true;
        }
      }
    },
    currentUserInfo: function currentUserInfo(val) {
      this.unreadWeChatMessageCount = 0;
      this.isCustomerService = false;
      this.customerServiceIconShow = false;

      if (val.UserID > 0) {
        this.init();
      }
    },
    unreadWeChatMessageCount: function unreadWeChatMessageCount(val) {
      var _this = this;

      if (val > 0 && this.currentRouteName !== 'WeChatOfficialAccountsMessageReply') {
        this.animationName = 'tada';
        this.customerServiceIconShow = true;
        setTimeout(function () {
          _this.animationName = '';
        }, 1000);
      }
    },
    customerServiceIconShow: function customerServiceIconShow(val) {
      var _this2 = this;

      if (val === true) {
        this.animationName = 'zoomInUp';
        setTimeout(function () {
          _this2.animationName = '';
        }, 1000);
      } else {
        this.animationName = 'zoomOutDown';
        setTimeout(function () {
          _this2.animationName = 'hidden';
        }, 1000);
      }
    }
  },
  created: function created() {
    // 初始化
    this.init(); // 建立websocket连接

    createSocket(); // 全局监听websocket消息、命令

    window.addEventListener('websocketMessage', this.websocketMessage);
    window.addEventListener('websocketCommand', this.websocketCommand); // 更新用户活跃时间,每分钟

    this.timer = setInterval(function () {
      wsSendCommand('UpdateActiveTime', null);
    }, 60000); // 设置项目配置

    projectConst().then(function (res) {
      setProjectConst(res['Data']);
    }); // 设置状态

    projectStatus().then(function (res) {
      setProjectStatus(res['Data']);
    }); // 设置字典

    projectDict().then(function (res) {
      setProjectDict(res['Data']);
    });
  },
  beforeDestroy: function beforeDestroy() {
    // 销毁事件监听
    window.removeEventListener('websocketMessage', this.websocketMessage);
    window.removeEventListener('websocketCommand', this.websocketCommand); // 销毁定时器

    clearInterval(this.timer);
  },
  methods: {
    init: function init() {
      var _this3 = this;

      if (this.currentUserInfo.UserID !== undefined && this.$store.state.user.infos.IsCustomerService) {
        // 未读消息统计
        this.unreadWeChatMessageCountFunc().then(function () {
          setTimeout(function () {
            _this3.isCustomerService = _this3.$store.state.user.infos.IsCustomerService; // 客服或者有未读消息才显示图标

            if ((_this3.isCustomerService || _this3.unreadWeChatMessageCount > 0) && _this3.currentRouteName !== 'WeChatOfficialAccountsMessageReply') {
              _this3.customerServiceIconShow = true;
            }
          }, 1000);
        });
      }
    },
    websocketMessage: function websocketMessage(e) {
      var data = e.detail.data; // websocket登录成功后的操作在此处执行

      if (data.Name === 'Login' && data.Data.Code === 200) {
        // 如果开启新订单提醒，向服务器订阅新订单提醒频道
        if (parseInt(localStorage.getItem('SubscribeNewMemberOrderStatus')) === 1) {
          subscribeNewMemberOrder();
        }
      } // 收到微信新消息


      if (data.Name === 'NewWeChatMessage' && this.currentRouteName !== 'WeChatOfficialAccountsMessageReply') {
        this.unreadWeChatMessageCount++;
        this.$notify({
          title: '提示',
          message: '您有一条新的微信消息',
          duration: 3000
        });
      } // 收到新订单通知


      if (data.Name === 'NewMemberOrder') {
        var _that = this; // 播放提示音


        _that.$newOrderAudio.play();

        this.$myNotify({
          title: '提示',
          dangerouslyUseHTMLString: true,
          message: '<span href="/#/member_order/member_order">您有 <span class="text-danger">' + data.Data['TotalPendingMemberOrderCount'] + '</span> 条待处理订单，请前往处理！</a>',
          duration: 0,
          onClick: function onClick() {
            _that.$router.push({
              path: '/member_order/member_order'
            });

            this.close();
          }
        }, 'NewMemberOrderNotify', true);
      }
    },
    websocketCommand: function websocketCommand(e) {// const data = e.detail.data
    },
    weChatMessageReply: function weChatMessageReply() {
      // 客服跳转到微信聊天页
      if (this.isCustomerService) {
        this.unreadWeChatMessageCount = 0;
        this.customerServiceIconShow = false;
        this.$router.push({
          path: '/wechat/wechat_official_accounts_message_reply'
        });
      } else {
        // 非客服打开聊天框
        this.$refs.myWindowWeChatMessage.show();
      }
    },
    unreadWeChatMessageCountFunc: function unreadWeChatMessageCountFunc() {
      var _this4 = this;

      return new Promise(function (resolve, reject) {
        unreadWeChatOfficialAccountsMessageCount().then(function (res) {
          _this4.unreadWeChatMessageCount = res.Data;
          resolve();
        });
      });
    }
  }
};