//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'Hamburger',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleClick: function toggleClick() {
      this.$emit('toggleClick');
    }
  }
};