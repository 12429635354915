var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c("logo", { attrs: { collapse: _vm.isCollapse } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": _vm.variables.menuBg,
                "text-color": _vm.variables.menuText,
                "unique-opened": true,
                "active-text-color": _vm.variables.menuActiveText,
                "collapse-transition": false,
                mode: "vertical"
              }
            },
            _vm._l(_vm.permission_routes, function(route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: { item: route, "base-path": route.path }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showLogo
        ? _c(
            "div",
            {
              staticClass: "sidebar-logo-container-company",
              class: { collapse: _vm.isCollapse }
            },
            [
              _c(
                "transition",
                { attrs: { name: "sidebarLogoFade" } },
                [
                  _vm.isCollapse
                    ? _c(
                        "router-link",
                        {
                          key: "collapse",
                          staticClass: "sidebar-logo-link",
                          attrs: { to: "/" }
                        },
                        [
                          _c("img", {
                            staticClass: "sidebar-logo",
                            attrs: { src: _vm.SidebarLogo }
                          })
                        ]
                      )
                    : _c(
                        "router-link",
                        {
                          key: "expand",
                          staticClass: "sidebar-logo-link",
                          attrs: { to: "/" }
                        },
                        [
                          _c("img", {
                            staticClass: "sidebar-logo",
                            attrs: { src: _vm.SidebarLogo }
                          }),
                          _vm._v(" "),
                          _c("h1", { staticClass: "sidebar-title" }, [
                            _vm._v(_vm._s(_vm.SidebarName))
                          ])
                        ]
                      )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }