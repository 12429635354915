//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: 'VIP大客户端',
      logo: require("../../../assets/images/logo.png"),
      version: ""
    };
  },
  created: function created() {
    this.version = parseFloat(this.$getProjectConst('SysVersion')).toFixed(1);
    this.logo = this.$showFileUrl(this.$getProjectConst('Sidebar_Top_Logo'));
    this.title = this.$getProjectConst('Sidebar_Top_Name');
  }
};