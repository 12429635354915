import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
import { config } from '@/const/config';
import { getBrowserName, getOS } from '@/utils/index'; // create an axios instance

var service = axios.create({
  baseURL: config().API_URL,
  // 测试环境,
  timeout: 30000
}); // request interceptor

service.interceptors.request.use(function (config) {
  // 发出请求前配置
  if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['Token'] = getToken();
  }

  config.headers['Lang'] = 'ZH';
  config.headers['ClientName'] = 'PC';
  config.headers['OS'] = getOS();
  config.headers['TimeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  config.headers['Device'] = getBrowserName();
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;

  if (res.Msg === 'Token验证错误') {
    res.Msg = '您登录超时，请重新登录！';
  } // const headers = response.headers
  // if (headers['content-type'] !== 'application/json') {
  //     console.log(headers)
  //     return res
  // }
  // if the custom code is not 20000, it is judged as an error.


  if (res.Code !== 200) {
    //返回文件流直接输出
    if (res.Code === undefined) {
      return res;
    }

    var data = response.config.data;

    if (!data.includes('HideErrorMessage')) {
      Message({
        message: res.Msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      });
    } //Token错误，重新登录


    if (res.code === 202) {
      MessageBox.alert('您的账号已超时或异地登录，请重新登录', '', {
        type: 'warning',
        showClose: false
      }).then(function () {
        store.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      });
    } // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;

    /*  if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
       // to re-login
       MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
         confirmButtonText: 'Re-Login',
         cancelButtonText: 'Cancel',
         type: 'warning'
       }).then(() => {
         store.dispatch('user/resetToken').then(() => {
           location.reload()
         })
       })
     } */


    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    return res;
  }
}, function (error) {
  // console.log('err' + error) // for debug
  if (error.message === 'Token验证错误') {
    error.message = '您登录超时，请重新登录！';
  }

  Message({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;