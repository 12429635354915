import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import { Message } from 'element-ui';
Vue.use(VueClipboard); //↓ 调用时需要在created或mounted中加这行代码 ↓
//  window.copy = this.$copy

export function copy(text) {
  Vue.prototype.$copyText(text).then(function (e) {
    Message({
      message: '复制成功',
      type: 'success'
    });
  }, function (e) {
    Message({
      message: '该浏览器不支持自动复制',
      type: 'warning'
    });
  });
} //text复制的内容
//isShowText 为false只显示图标，为true显示text+图标，默认为false
//showText 显示的文字

export function copyBtn(text, isShowText, showText) {
  if (showText === '' || showText === undefined) {
    showText = text;
  }

  if (isShowText) {
    return '<a href="javascript:void(0);" onclick=copy("' + text + '") title="点击复制"><span class="el-link el-link--default is-underline copy-text">' + showText + ' <i class="el-icon-copy-document copy-btn"></i></span></a>';
  }

  return '<a href="javascript:void(0);" onclick=copy("' + text + '") title="点击复制"><i class="el-icon-copy-document copy-btn"></i></a>';
}